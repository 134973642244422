@tailwind base;
@tailwind components;
@tailwind utilities;

  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body, html{
  min-height: 100vh;
  overflow-x:hidden;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.bg-berk-gradient{
  background:linear-gradient(180deg, #2D3135 0%, #3E4347 100%)
}


.nav-item{
  list-style-type:none;
}